import * as React from "react";
import { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Text, Link } from "@aws-amplify/ui-react";
import _ from "lodash";

export function Audit(props) {
	var isAdmin = props.isAdmin.ADMIN_USER;

	const [state, setState] = useState({
		column: null,
		data: null,
		direction: null,
	});
	const { column, data, direction } = state;
	const handleSort = (clickedColumn) => () => {
		if (column !== clickedColumn) {
			props.setAuditEvents(_.sortBy(props.auditEvents, [clickedColumn]));
			setState({
				data: _.sortBy(data, [clickedColumn]),
				column: clickedColumn,
				direction: "ascending",
			});
			return;
		}

		setState({
			data: props.auditEvents.reverse(),
			column: clickedColumn,
			direction: direction === "ascending" ? "descending" : "ascending",
		});
	};

	return (
		<div>
			{isAdmin ? (
				<Table highlightOnHover={true} variation="striped">
					<TableHead>
						<TableRow>
							<TableCell as="th" sorted={column === "action" ? direction : null} onClick={handleSort("action")}>
								<Link href="#">Action</Link>
							</TableCell>
							<TableCell as="th" sorted={column === "user" ? direction : null} onClick={handleSort("user")}>
								<Link href="#">User</Link>
							</TableCell>
							<TableCell as="th" sorted={column === "date" ? direction : null} onClick={handleSort("date")}>
								<Link href="#">Date</Link>
							</TableCell>
							<TableCell as="th" sorted={column === "addedby" ? direction : null} onClick={handleSort("addedby")}>
								<Link href="#">Added By</Link>
							</TableCell>
							<TableCell as="th">Services</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.auditEvents
							? props.auditEvents.map((event) => {
									// console.log(`event ${JSON.stringify(event)}`);
									return (
										<TableRow key={event.sk}>
											<TableCell>{event.action}</TableCell>
											<TableCell>{event.user}</TableCell>
											<TableCell>{event.date}</TableCell>
											<TableCell>{event.addedby}</TableCell>
											<TableCell>{event.services}</TableCell>
										</TableRow>
									);
							  })
							: null}
					</TableBody>
				</Table>
			) : (
				<Text>GTFO</Text>
			)}
		</div>
	);
}
