import * as React from "react";
import {
	Alert,
	Text,
	TextField,
	Radio,
	RadioGroupField,
	ToggleButton,
	ToggleButtonGroup,
	Expander,
	ExpanderItem,
	Button,
	Flex,
} from "@aws-amplify/ui-react";
import JSONPretty from "react-json-pretty";
import { DuoRoleSelector } from "./DuoRoleSelector";

var JSONPrettyMon = require("react-json-pretty/dist/monikai");

export function UserForm(props) {
	var formState = props.formState;
	var setFormState = props.setFormState;
	var isAdmin = props.isAdmin.ADMIN_USER; // TODO This is not working as expected. The async nature is returning undefined before a isadmin check is finished.
	const [showGithub, setShowGithub] = React.useState(false);
	const [showOrgs, setShowOrgs] = React.useState(false);
	const [showDuo, setShowDuo] = React.useState(false);
	const [showSnowflake, setShowSnowflake] = React.useState(false);

	const [invalidEmail, setInvalidEmail] = React.useState(false);
	const [invalidForm, setInvalidForm] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	// small helper function for oddly named keys.
	function addChangeToState(key, value) {
		setFormState({
			...formState,
			[key]: value,
		});
	}

	return (
		<div>
			{invalidForm ? (
				<Alert isDismissible={false} variation="error" hasIcon={true} heading="Alert heading">
					Invalid Email!
				</Alert>
			) : null}

			<form
				onSubmit={(e) => {
					if (invalidEmail) {
						setInvalidForm(true);
						e.preventDefault()
					} else {
						handleSubmit(e);
					}
				}}
			>
				<RadioGroupField label="Action" name="action" defaultValue="add" direction="row" onChange={(e) => addChangeToState("action", e.target.value)}>
					<Radio value="add">Add</Radio>
					{/* Hide remove button if user isn't an admin */}
					{isAdmin ? <Radio value="remove">Remove</Radio> : null}
				</RadioGroupField>

				<TextField
					placeholder={isAdmin ? "Frodo Baggins" : props.user.attributes.name}
					label="Name"
					isDisabled={!isAdmin}
					onChange={(e) => setFormState({ ...formState, user: { ...formState.user, name: e.target.value.trim() } })}
				/>
				<TextField
					placeholder={isAdmin ? "Mr.Underhill@Hearsaycorp.com" : formState.user.email}
					hasError={invalidEmail}
					errorMessage="Please enter a '@hearsaycorp.com' address"
					label="Email"
					isDisabled={!isAdmin}
					onChange={(e) => {
						setFormState({ ...formState, user: { ...formState.user, email: e.target.value.trim() } });
						if (e.target.value.toLowerCase().includes("@hearsaycorp.com")) {
							setInvalidEmail(false);
							setInvalidForm(false);
						} else {
							setInvalidEmail(true);
						}
					}}
				/>
				<Text>Services</Text>
				<Flex wrap="wrap">
					<ToggleButtonGroup
						value={formState.services}
						onChange={(value) => {
							if (!formState.services.includes(value)) {
								addChangeToState("services", value);
							}
						}}
					>
						<ToggleButton defaultPressed value="pagerduty">
							PagerDuty
						</ToggleButton>
						<ToggleButton value="datadog">Datadog</ToggleButton>
						<ToggleButton value="sentry">Sentry</ToggleButton>
						<ToggleButton value="sumologic">Sumologic</ToggleButton>
						<ToggleButton
							value="backstage"
							isPressed={showOrgs}
							onClick={() => {
								setShowOrgs(!showOrgs);
							}}
						>
							Backstage
						</ToggleButton>

						{/* Only github show if user is an admin */}
						{isAdmin ? (
							<ToggleButton
							value="github"
							isPressed={showGithub}
							onClick={() => {
								setShowGithub(!showGithub);
							}}
							>
								Github
							</ToggleButton>
						) : null}

						{/* Only snowflake show if user is an admin */}
						{isAdmin ? (<ToggleButton 
							value="duo"
							isPressed={showDuo}
							onClick={() => {								
								setShowDuo(!showDuo);
							}}
						>
							Duo
						</ToggleButton>) : null }
						{isAdmin ? (
							<ToggleButton
								value="snowflake"
								isPressed={showSnowflake}
								onClick={() => {
									setShowSnowflake(!showSnowflake);
								}}
							>
								Snowflake
							</ToggleButton>
						) : null}
					</ToggleButtonGroup>
				</Flex>
				{/* Only Show Github username when github service is selected */}
				{showGithub ? (
					<div>
						<TextField
							placeholder="ring-bearer"
							label="Github Username"
							onChange={(e) => setFormState({ ...formState, user: { ...formState.user, gh_username: e.target.value.replace(/\s/g, "") } })}
						/>
						<TextField
							placeholder="Sackville-Bagginses,TheFellowship"
							label="Github Teams"
							onChange={(e) => setFormState({ ...formState, user: { ...formState.user, gh_teams: e.target.value.replace(/\s/g, "").split(",") } })}
						/>
					</div>
				) : null}

				{/* Only Show orgs username when service is selected */}
				{showOrgs ? (
					<TextField
						placeholder="1,50"
						label="Backstage Orgs"
						onChange={(e) => setFormState({ ...formState, user: { ...formState.user, orgs: e.target.value.replace(/\s/g, "").split(",") } })}
					/>
				) : null}
				{/* Only show duo when service is selected */}
				{showDuo ? <DuoRoleSelector groups={props.duoGroups} setFormState={props.setFormState} formState={props.formState} /> : null}
				<br />
				<Button variation="primary" type="submit" onClick={props.handleFormSubmit}>
					Submit
				</Button>
			</form>
			<br />
			<Expander
				isCollapsible={true}
				style={{
					backgroundColor: `#142433`,
					color: `#FFFFFF`,
				}}
			>
				<ExpanderItem title="POST Data" label="POST Data" value="PostData">
					<JSONPretty data={formState} theme={JSONPrettyMon}></JSONPretty>

				</ExpanderItem>
			</Expander>
		</div>
	);
}
