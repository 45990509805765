import { Link, Flex, Heading, Button, Divider, Text } from "@aws-amplify/ui-react";
import { Link as ReactRouterLink } from "react-router-dom";

function isDevOrLocalhost() {
	if (window.location.hostname === "dev.self-service.hssops.com" || window.location.hostname === "localhost") {
		return true;
	} else {
		return false;
	}
}
export function HeaderNav(props) {
	var isAdmin = props.isAdmin.ADMIN_USER;
	return (
		<div>
			<Flex>
				{isDevOrLocalhost() ? (
					<Heading color="red" level={1}>
						NOT PROD
					</Heading>
				) : null}
				<Heading level={3}>Zeus</Heading>
				<Divider orientation="vertical" />
				<ReactRouterLink to="/" component={Link}>
					<Text>Home</Text>
				</ReactRouterLink>
				{isAdmin ? (
					<ReactRouterLink to="/audit" component={Link}>
						<Text>Audit</Text>
					</ReactRouterLink>
				) : null}
				{isAdmin ? (
					<ReactRouterLink to="/approval" component={Link}>
						<Text>Approval</Text>
					</ReactRouterLink>
				) : null}
				<Button onClick={props.signOut} position="absolute" right="0.5rem" top="0.5rem">
					Sign out
				</Button>
			</Flex>
			<Divider />
		</div>
	);
}
