import * as React from "react";
import "./App.css";
import { Amplify, API, Auth } from "aws-amplify";

import { Authenticator, Placeholder, Button, Alert } from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";

import { ResponseDisplay } from "./components/ResponseDisplay";
import { UserForm } from "./components/UserForm";
import { HeaderNav } from "./components/Header";
import { Audit } from "./components/Audit";
import { Approval } from "./components/Approval";

function getEnvironmentSpecificUrls() {
	const [localRedirectSignIn, devRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(",");
	const [localRedirectSignOut, devRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(",");
	switch (window.location.hostname) {
		case "localhost":
			redirectSignIn = localRedirectSignIn;
			redirectSignOut = localRedirectSignOut;
			break;
		case "dev.self-service.hssops.com":
			redirectSignIn = devRedirectSignIn;
			redirectSignOut = devRedirectSignOut;
			break;
		case "self-service.hssops.com":
			redirectSignIn = productionRedirectSignIn;
			redirectSignOut = productionRedirectSignOut;
			break;
		default:
			console.log("in default case");
			redirectSignIn = devRedirectSignIn;
			redirectSignOut = devRedirectSignOut;
	}
	return [redirectSignIn, redirectSignOut];
}
const awsConfig = {
	Auth: {
		region: "us-west-2",
		userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
	},
	API: {
		endpoints: [
			{
				name: "test",
				endpoint: "https://api.dev.self-service.hssops.com/",
			},
			{
				name: "zeus",
				endpoint: process.env.REACT_APP_ZEUS_API || "https://api.dev.self-service.hssops.com/",
			},
		],
	},
	oauth: {
		domain: process.env.REACT_APP_COGNITO_URL,
		scope: ["profile", "email", "openid", "aws.cognito.signin.user.admin"],
		clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
		redirectSignIn: "http://localhost:3000/,https://dev.self-service.hssops.com/,https://self-service.hssops.com/",
		redirectSignOut: "http://localhost:3000/,https://dev.self-service.hssops.com/,https://self-service.hssops.com/",
		responseType: "token",
	},
};
var [redirectSignIn, redirectSignOut] = getEnvironmentSpecificUrls();

const updatedAwsConfig = {
	...awsConfig,
	oauth: {
		...awsConfig.oauth,
		redirectSignIn: redirectSignIn,
		redirectSignOut: redirectSignOut,
	},
};
Amplify.configure(updatedAwsConfig);

export default App;
function App({ signOut, user }) {
	const [isLoading, setIsLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState();
	const [roles, setRoles] = React.useState();
	const [auditEvents, setAuditEvents] = React.useState();
	const [approvals, setApprovals] = React.useState();
	const [duoGroups, setDuoGroups] = React.useState([]);
	const [isAdmin, setIsAdmin] = React.useState(false);
	const [apiFormResponse, setApiFormResponse] = React.useState([""]);

	const [formState, setFormState] = React.useState({
		action: "add",
		services: [],
		user: {
			orgs: [],
			teams: ["allusers"],
			duo_groups: [],
		},
	});

	const handleFormSubmit = async () => {
		setIsLoading(true);

		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
			body: formState,
		};

		await API.post("zeus", "/zeus", myInit)
			.then((data) => {
				setApiFormResponse(data);
				setIsLoading(false);
			})
			.catch((err) => {
				// console.log(`error: ${JSON.stringify(err)}`);
				setErrorMessage(JSON.stringify(err.status));
				setIsError(true);
				setIsLoading(false);
				console.error(`errorMessage: ${JSON.stringify(errorMessage)}`);
			});
	};

	// Get roles from API
	const getRoles = async () => {
		setIsLoading(true);
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
		};
		await API.get("zeus", "/roles", myInit)
			.then((data) => {
				setRoles(data.roles);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(`error: ${JSON.stringify(err)}`);
				setIsError(true);
				setIsLoading(false);
				console.error(err);
			});
	};

	// Get audit events from API
	const getAuditEvents = async () => {
		setIsLoading(true);
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
		};
		await API.get("zeus", "/audit", myInit)
			.then((data) => {
				setAuditEvents(
					data.log_events.map((event) => {
						var [action, user, date] = event.sk.split("#");
						var services = event.Services.join(" ");
						return {
							sk: event.sk,
							action: action,
							user: user,
							date: date,
							addedby: event.AddedBy,
							services: services,
						};
					})
				);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(`error: ${JSON.stringify(err)}`);
				setIsError(true);
				setIsLoading(false);
				console.error(err);
			});
	};
	const getApprovals = async () => {
		setIsLoading(true);
		
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
		};
		return API.get("zeus", "/approvals", myInit).then((data) => {

			setApprovals(data.approvals);
			setIsLoading(false);
		});
	};
	const updateApprovals = async (sk, status) => {
		setIsLoading(true);
		
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
			body: { sk: sk, status: status },
		};
		return API.post("zeus", "/approvals", myInit).then((data) => {	
			setIsLoading(false);
			getApprovals();
		});
	};
	const getIsAdmin = async () => {
		setIsLoading(true);
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
			body: { email: user },
		};
		await API.post("zeus", "/isAdmin", myInit)
			.then((data) => {
				setIsAdmin(data);
				// console.log(data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(`error: ${JSON.stringify(err)}`);
				setIsError(true);
				setIsLoading(false);
				console.error(err);
			});
	};
	const getDuoGroups = async () => {
		setIsLoading(true);
		const cognito_api_config = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
			},
			body: { email: user },
		}
		let res = await API.get("zeus", "/duo/groups", cognito_api_config)
		setDuoGroups(res);
		setIsLoading(false);
		// return API.get("zeus", "/duo/groups", cognito_api_config).then((data) => {
		// 	setDuoGroups(data);
		// 	console.log(data)
		// });
		};
	React.useEffect(() => {
		getDuoGroups();
		getIsAdmin();
		getAuditEvents();
		getApprovals();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<Authenticator hideSignUp={true} socialProviders={["google"]}>
			{({ signOut, user }) => (
				<Router>
					<main className="App">
						<header className="App-header jsonbody">
							<HeaderNav isAdmin={isAdmin} />
							{isError ? (
								<Alert isDismissible={true} onDismiss={() => setIsError(false)} variation="error" position="absolute" left="0.5rem" top="0.5rem">
									Error! Hopefully, I become more useful in the future! {errorMessage}
								</Alert>
							) : null}
						</header>
						<Button onClick={signOut} position="absolute" right="0.5rem" top="0.5rem">
							Sign out
						</Button>
						
					</main>
					<Routes>
						<Route path="/audit" element={<Audit isAdmin={isAdmin} auditEvents={auditEvents} setAuditEvents={setAuditEvents} />} />
						<Route
							path="/"
							element={
								<UserForm
									handleFormSubmit={handleFormSubmit}
									setFormState={setFormState}
									formState={formState}
									roles={roles}
									getRoles={getRoles}
									isAdmin={isAdmin}
									user={user}
									duoGroups={duoGroups}
								></UserForm>
							}
						/>
						<Route path="/approval" element={<Approval isAdmin={isAdmin} approvals={approvals} getApprovals={getApprovals} updateApprovals={updateApprovals} />} />
					</Routes>
					{isLoading ? <Placeholder /> : <ResponseDisplay apiFormResponse={apiFormResponse}></ResponseDisplay>}
				</Router>
			)}
		</Authenticator>
	);
}
