import * as React from "react";
import { Text, SwitchField } from "@aws-amplify/ui-react";
import JSONPretty from "react-json-pretty";
var JSONPrettyMon = require("react-json-pretty/dist/monikai");

export function ResponseDisplay(props) {
	const [showJson, setShowJson] = React.useState(false);

	return (
		<div>
			<SwitchField
				label="Show JSON Response"
				isChecked={showJson}
				onChange={(e) => {
					setShowJson(e.target.checked);
				}}
			/>
			{props.apiFormResponse.services
				? props.apiFormResponse.services.map((service) => (
						<Text key={service.name}>
							{service.name}: {service.status === "OK" ? "✅" : "❌"}
						</Text>
				  ))
				: null}
			{showJson ? <JSONPretty data={props.apiFormResponse} theme={JSONPrettyMon}></JSONPretty> : null}
		</div>
	);
}
