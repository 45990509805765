import * as React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export function DuoRoleSelector(props) {
	var formState = props.formState;
	var setFormState = props.setFormState;

	// Dark mode for react-select
	const colorStyles = {
		control: (styles) => ({ ...styles, borderColor: "rgb(137, 148, 159)", backgroundColor: "#0d1a26", option: "#0d1a26" }),
		menuList: (styles) => ({ ...styles, color: "grey", backgroundColor: "#25303b" }),
		input: (styles) => ({ ...styles, color: "grey" }),
	};
	// grab just group name and group_id from groups array
	var remappedArray = props.groups.map(({ group_id, name }) => ({ value: group_id, label: name }));
	const handleChange = (selectedOptions) => {
		// Grab just values(duo group_id) from selected options and set formState
		var tempGroups = selectedOptions.map(({ value }) => value);

		setFormState({ ...formState, user: { ...formState.user, duo_groups: tempGroups } });
	};
	return (
		<div>
			{/* If remapped array is truthy display the select component otherwise nothing*/}
			{remappedArray ? (
				<div>
					<br />
					<Select
						className="react-select-container"
						classNamePrefix="react-select"
						closeMenuOnSelect={false}
						isMulti
						components={animatedComponents}
						onChange={handleChange}
						options={remappedArray}
						maxMenuHeight={500}
						styles={colorStyles}
					/>
				</div>
			) : null}
		</div>
	);
}
