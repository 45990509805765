import * as React from "react";
import { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Text, Link, Button } from "@aws-amplify/ui-react";
import _ from "lodash";

export function Approval(props) {
	var isAdmin = props.isAdmin.ADMIN_USER;
	const [state, setState] = useState({
		column: null,
		data: null,
		direction: null,
	});

	const { column, data, direction } = state;
	const handleSort = (clickedColumn) => () => {
		if (column !== clickedColumn) {
			props.setAuditEvents(_.sortBy(props.auditEvents, [clickedColumn]));
			setState({
				data: _.sortBy(data, [clickedColumn]),
				column: clickedColumn,
				direction: "ascending",
			});
			return;
		}

		setState({
			data: props.auditEvents.reverse(),
			column: clickedColumn,
			direction: direction === "ascending" ? "descending" : "ascending",
		});
	};

	return (
		<div>
			<Button onClick={() => props.getApprovals()}>Get Approvals</Button>
			{isAdmin ? (
				<Table highlightOnHover={true} variation="striped">
					<TableHead>
						<TableRow>
							<TableCell as="th" sorted={column === "user" ? direction : null} onClick={handleSort("user")}>
								<Link href="#">User</Link>
							</TableCell>
							<TableCell as="th" sorted={column === "date" ? direction : null} onClick={handleSort("date")}>
								<Link href="#">Start Date</Link>
							</TableCell>
							<TableCell as="th" sorted={column === "addedby" ? direction : null} onClick={handleSort("addedby")}>
								<Link href="#">Issue Reporter</Link>
							</TableCell>
							<TableCell as="th">Services Requested</TableCell>
							<TableCell as="th">Jira Ticket</TableCell>
							<TableCell as="th">Approve/Deny</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.approvals
							? props.approvals.map((event) => {
									console.log(`event ${JSON.stringify(event, null, 4)}`);
									return (
										<TableRow key={event.sk}>
											<TableCell>{event.body.email}</TableCell>
											<TableCell>{event.body.start_date}</TableCell>
											<TableCell>{event.body.reporter}</TableCell>
											<TableCell>{event.body.services ? event.body.services.map((service) => `${service.value} `) : "None"}</TableCell>
											<TableCell>
												<Link href={"https://hearsaysocial.atlassian.net/browse/" + event.body.jira_ticket}>{event.body.jira_ticket}</Link>
											</TableCell>
											<TableCell>
												<Button variation="primary" onClick={() => props.updateApprovals(event.sk, "approved")}>
													Approve
												</Button>
												<Button variation="destructive" onClick={() => props.updateApprovals(event.sk, "deny")}>
													Deny
												</Button>
											</TableCell>
										</TableRow>
									);
							  })
							: null}
					</TableBody>
				</Table>
			) : (
				<Text>GTFO</Text>
			)}
		</div>
	);
}
